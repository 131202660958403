<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>

  <!-- 二维码功能 -->
  <vxe-modal ref="xModal" title="二维码" width="500" show-zoom>
    <template v-slot>
      <div class="myPrint">
        <img :src="qrCodeUrl" />
        <h2>巡检点：{{selectRow.inspectionName}}</h2>
        <p>备注：请打印后粘贴到巡检点</p>
      </div>
      <div class="btn">
        <vxe-button status="primary" content="打印" @click="printEvent"></vxe-button>
      </div>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import VXETable from "vxe-table";
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "inspectionName",
            title: "巡检名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检点名称"
              }
            }
          },
          {
            field: "inspectionPlace",
            title: "巡检位置",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 3,
              }
            },
          },
          {
            field: "createTime",
            title: "创建时间",
            width: 180

          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   width: 120,
          //   cellRender: {
          //     name: "$switch",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       openLabel: "启用",
          //       closeLabel: "禁用",
          //       openValue: "1",
          //       closeValue: "0"
          //     },
          //     events: {
          //       change: ({ row }) => {
          //         this.$refs.xGrid.submitEvent(row)
          //       }
          //     }
          //   }
          // },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "qrcode",
                  status: "primary",
                  label: "二维码",
                  eventName: "customEvent",
                  events: {
                    click: (row) => {
                      this.getQrcodeEvent(row)
                    }
                  }
                },
                {
                  name: "edit",
                  status: "primary",
                  label: "编辑",
                  eventName: "queryEvent"
                },
                {
                  name: "delete",
                  status: "danger",
                  label: "删除",
                  eventName: "removeEvent"
                }
              ]
            }
          }
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择所属小区')
                  }
                }
              }
            },
          },
          {
            field: "inspectionName",
            title: "巡检名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检名称"
              }
            }
          },
          {
            field: "inspectionPlace",
            title: "巡检位置",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检位置"
              }
            }
          },
          // {
          //   field: "orderCode",
          //   title: "排序",
          //   span: 12,
          //   itemRender: {
          //     name: "$input",
          //     props: {
          //       maxlength: 3,
          //       placeholder: "请输入排序号",
          //     },
          //   },
          // },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   span: 12,
          //   itemRender: {
          //     name: "$select",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       placeholder: "请选择状态"
          //     }
          //   }
          // },
          // {
          //   field: "remark",
          //   title: "描述",
          //   span: 24,
          //   itemRender: {
          //     name: "$textarea",
          //     props: {
          //       showWordCount: true,
          //       maxlength: "300",
          //       resize: "none",
          //       placeholder: "请输入描述内容"
          //     }
          //   }
          // },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属小区" }],
          inspectionName: [{ required: true, message: "请输入巡检名称" }],
          inspectionPlace: [{ required: true, message: "请输入巡检位置" }]
        },
        // 新增功能字段初始化
        data: {
          enabled: "1",
          clientType: "1",
          content: ""
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "inspectionName",
            title: "巡检名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检名称"
              }
            }
          },
          {
            field: "inspectionPlace",
            title: "巡检位置",
            folding: true,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检位置"
              }
            }
          },
          {
            align: "center",
            collapseNode: true,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          inspectionName: "",
          inspectionPlace: "",
          enabled: ""
        },
      },
      qrCodeUrl: "",
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data['parentId'] = node.id;
      this.formOptions.data['communityId'] = node.id;
      this.formOptions.data['enabled'] = '1';
      this.searchOptions.data['parentId'] = node.id;
      this.$refs.xGrid.getLists();
    },
    getQrcodeEvent(row) {
      this.$Axios.get(`/wisdom/inspectionPoint/QRCode/${row.id}`).then(({ code, data, message }) => {
        if (code == 200) {
          this.selectRow = row;
          this.qrCodeUrl = 'data:image/jpg;base64,' + data
          this.$refs.xModal.open();
        } else {
          this.$message.error('二维码生成失败')
        }
      })
    },

    printEvent() {
      const printStyle = `
        * {
          margin: 0 auto;
        }

        .myPrint {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-content:center;
          justify-content: center;
        }

        .myPrint h2 {
          font-size:48px;
          padding: 50px 0;
        }

        .myPrint p {
          font-size:24px;
          padding: 30px 0;
        }
      `
      const printTmpl = `
         <div class="myPrint">
          <img src="${this.qrCodeUrl}" />
          <h2>巡检点：${this.selectRow.inspectionName}</h2>
          <p>备注：请打印后粘贴到巡检点</p>
        </div>
      `
      VXETable.print({
        sheetName: '打印下面区域',
        style: printStyle,
        content: printTmpl
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.myPrint {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.myPrint h2 {
  padding: 10px 0;
}

.myPrint p {
  padding: 20px 0;
}

.btn {
  padding-top: 30px;
  text-align: center;
}
</style>
